export interface ScrollOptions {
    delay?: number;
    scrollOffsetAbove?: number;
    scrollOffsetBelow?: number;
    scrollOffsetElementId?: string | null;
    behavior?: ScrollBehavior;
}

export const scrollToSection = (targetId: string, options: ScrollOptions = {}): void => {
    const {
        delay = 200,
        scrollOffsetAbove = 0,
        scrollOffsetBelow = 0,
        behavior = 'smooth',
        scrollOffsetElementId = null,
    } = options;

    setTimeout(() => {
        const targetElement = document.getElementById(targetId);
        const header = scrollOffsetElementId ? document.getElementById(scrollOffsetElementId) : null;
        const headerHeight = header ? header.clientHeight : 0;

        if (targetElement) {
            const scrollPosition = targetElement.getBoundingClientRect().top + window.scrollY - headerHeight - scrollOffsetAbove;

            window.scrollTo({
                top: scrollPosition + scrollOffsetBelow,
                behavior,
            });
        }
    }, delay);
};
